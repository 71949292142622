
import { defineComponent, computed, reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { verifyMobileOtp, resendMobileVerification } from '@/modules/requests';
import { useRouter } from 'vue-router';
import { AxiosResponse, AxiosError } from 'axios';

export default defineComponent({
  setup: () => {
    const { state, dispatch } = useStore();
    const { basicForm } = resource();
    const { validateForm } = validation();

    const { push } = useRouter();

    const successModal = ref<any>(null);

    const data = reactive({
      form: basicForm([
        {
          name: 'otp_code',
          rules: 'required|number',
        },
      ]),
      message: '',
    });

    const phoneNumber = computed(() => state?.user?.mobile);

    const year = computed(() => new Date().getFullYear());

    const validateOtp = () => {
      if (!validateForm(data.form)) return false;

      data.form.loading = true;
      verifyMobileOtp(data.form.data.otp_code.value)
        .then((response: AxiosResponse) => {
          dispatch('updateUser', response.data).then(() => push('/dashboard'));
        })
        .catch((error) => (data.form.error = error?.response?.data?.message))
        .finally(() => (data.form.loading = false));
    };

    const resendOtp = async (): Promise<void> => {
      data.form.loading = true;
      await resendMobileVerification({
        number: phoneNumber.value,
      })
        .then((response) => {
          data.message = response?.message;
          successModal.value.open();
        })
        .catch(
          (error: AxiosError) =>
            (data.form.error = error?.response?.data?.message),
        )
        .finally(() => (data.form.loading = false));
    };

    return {
      phoneNumber,
      ...toRefs(data),
      validateOtp,
      resendOtp,
      successModal,
      year,
    };
  },
});
