<template>
  <div class="auth-layout">
    <logo />
    <div class="grid grid-cols-1 gap-6 sm:mt-12 sm:grid-cols-2">
      <div class="col-span-1 pt-8 sm:pt-16 hidden sm:block">
        <div class="text-4.5xl font-bold text-invest-primary">Get Started</div>
        <p class="mt-5 text-base text-invest-primary text-opacity-80">
          Create account and start investing
        </p>
      </div>
      <div class="flex justify-end col-span-1 mt-6 sm:mt-0">
        <div class="card">
          <div class="px-5">
            <alert variant="error" v-if="form.error">
              {{ form.error }}
            </alert>
            <h3 class="text-2xl font-bold">Verify phone number</h3>
            <p class="mt-5 text-xs text-invest-dark leading-relaxed">
              Enter the verification code sent to your <br />
              phone number
              <span class="text-invest-purple font-bold">
                {{ phoneNumber }}
              </span>
            </p>

            <form class="mt-16" @submit.prevent="validateOtp">
              <form-input
                height="h-16 text-center"
                placeholder="Enter code here"
                name="otp_code"
                :form="form"
                v-model="form.data.otp_code.value"
              />

              <p
                class="
                  mt-24
                  mb-20
                  text-xs text-invest-dark
                  leading-relaxed
                  text-center
                  font-normal
                "
              >
                Didn’t get verification code?
                <span
                  class="text-invest-purple font-bold cursor-pointer"
                  @click="resendOtp"
                >
                  Resent code
                </span>
              </p>

              <div class="mt-8">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="form.loading"
                >
                  <loader v-if="loading" />
                  <span v-else>Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="grid h-16 grid-cols-1 sm:h-48 place-content-end">
      <div class="flex justify-between text-xs text-invest-dark">
        <p>
          Copyright © {{ year }}, Crednet Technologies • All rights reserved.
        </p>
        <p class="font-semibold">Terms or Use • Policies</p>
      </div>
    </div>
  </div>
  <alert-modal ref="successModal">
    <template #subtitle>
      {{ message }}
    </template>
  </alert-modal>
</template>
<script lang="ts">
import { defineComponent, computed, reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { verifyMobileOtp, resendMobileVerification } from '@/modules/requests';
import { useRouter } from 'vue-router';
import { AxiosResponse, AxiosError } from 'axios';

export default defineComponent({
  setup: () => {
    const { state, dispatch } = useStore();
    const { basicForm } = resource();
    const { validateForm } = validation();

    const { push } = useRouter();

    const successModal = ref<any>(null);

    const data = reactive({
      form: basicForm([
        {
          name: 'otp_code',
          rules: 'required|number',
        },
      ]),
      message: '',
    });

    const phoneNumber = computed(() => state?.user?.mobile);

    const year = computed(() => new Date().getFullYear());

    const validateOtp = () => {
      if (!validateForm(data.form)) return false;

      data.form.loading = true;
      verifyMobileOtp(data.form.data.otp_code.value)
        .then((response: AxiosResponse) => {
          dispatch('updateUser', response.data).then(() => push('/dashboard'));
        })
        .catch((error) => (data.form.error = error?.response?.data?.message))
        .finally(() => (data.form.loading = false));
    };

    const resendOtp = async (): Promise<void> => {
      data.form.loading = true;
      await resendMobileVerification({
        number: phoneNumber.value,
      })
        .then((response) => {
          data.message = response?.message;
          successModal.value.open();
        })
        .catch(
          (error: AxiosError) =>
            (data.form.error = error?.response?.data?.message),
        )
        .finally(() => (data.form.loading = false));
    };

    return {
      phoneNumber,
      ...toRefs(data),
      validateOtp,
      resendOtp,
      successModal,
      year,
    };
  },
});
</script>
